.mumbaiPageBlock , .guwahatiPageBlock, .hyderabadPageBlock{
    height: 100%;
    padding: 80px 0px 36px 0px;
    position: relative;
}
.mumbaiPageBlock{
    background-color: #8b4dff;
}
.hyderabadPageBlock{
    background-color: #35b5f1;
}
.guwahatiPageBlock{
    background-color: #b400b4;
}

.cityInfoBlock{
    margin: auto;
    width: 90%;
    /* position: relative; */
}
.top-slideshow-beltt {
    position: absolute;
    left: 0px;
    background-size: 1500px;
    background-repeat: repeat-x;
    width: 100%;
    height: 59px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    margin-left: -690px;
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
} 
.eventDate{
    font-family: 'Roboto', sans-serif;
    line-height: 140%;
    font-size: 42px;
    color: #fff;
    text-align: center;
    font-weight: 900 ;
}
.eventType{
    font-family: 'Roboto', sans-serif;
    line-height: 30px;
    margin: 0px;
    font-size: 30px;
    color: #FFDE00;
    text-align: center;
    font-weight: 900 ;
}
.top-slideshow-beltt.slideright-ff {
    background-image: url('../Assets/city/leftAttach.svg');
}
.top-slideshow-beltt.slideright-ff {
    animation: slideright 400s infinite linear;
    -webkit-animation: slideright 400s infinite linear;
    background-image: url('../Assets/city/leftAttach.svg');
}
@keyframes slideright {
    0%{
        background-position: 0px;
    }
    100%{
        background-position: -10000px;
    }
}

.paraCityBlock{
    font-family: 'Roboto', sans-serif;
    line-height: 140%;
    font-size: 22px;
    color: #fff;
    text-align: center;
    font-weight: 700 ;
}
.cityImageBlock{
    max-width: 100%;
    height: 480px;
}
.textContainerBlock{
    margin-top: 32px;
    padding: 0px 4% 8% 4%;
}
.relative{
    position: relative;
}
.fixedFrame{
    position: absolute;
    top: 40px;
    left: 36px;
    height: 100%;
}
.fixedFrameRight{
    position: absolute;
    top: 40px;
    right: 36px;
    height: 100%;
}
#mumbai, #hyd, #gwh{
    font-family: 'Roboto', sans-serif;
    line-height: 46px;
    font-size: 46px;
    color: #000;
    text-align: center;
    font-weight: 700 ;
    margin-bottom: 4px;
}
.timer{
    font-family: 'Roboto', sans-serif;
    line-height: 46px;
    font-size: 46px;
    color: #000;
    text-align: center;
    font-weight: 700 ;
    margin-bottom: 4px;
}
.paTxt{
    font-family: 'Roboto', sans-serif;
    line-height: 22px;
    font-size: 22px;
    color: #000000;
    text-transform: uppercase;
    text-align: center;
    font-weight: 900 ;
    margin-top: 6px;
    margin-bottom: 8px;
    text-decoration: underline;
}
.announceBlock{
    background-color: #FFDE00;
    padding: 12px ;
    text-align: center;
    border: 2px solid #000;
    width: 40%;
    border-radius: 10px;
    margin: 22px auto;
}
.lineUpImg{
    max-width: 100%;
    height: auto;
}
.lineUpdDiv{
    margin: 60px 0px 50px;
    text-align: center;
}
.lineUpHead{
    margin-bottom: 42px;
    max-width: 100%;
    height: 80px;
}
.lineupWriteup{
    font-family: 'Roboto', sans-serif;
    line-height: 140%;
    font-size: 24px;
    color: #fff;
    text-align: center;
    font-weight: 700 ;
    margin-top: 32px;
}
.borderBottom{
    border-bottom: 1px solid #FFDE00;
    padding-bottom: 32px;
}
@media only screen and (max-width: 600px) {
    .fixedFrame,  .fixedFrameRight{
        display: none;        
    }
    .cityImageBlock {
        max-width: 100%;
        height: auto;
    }
    .announceBlock {
        border: 2px solid #000;
        width: 100%;
    }
    #mumbai, #hyd , #gwh{
        font-size: 28px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .paTxt{
        line-height: 120%;
        font-size: 16px;
        margin-bottom: 4px;
    }
    .paraCityBlock {
        font-family: 'Roboto', sans-serif;
        line-height: 140%;
        font-size: 18px;
        color: #fff;
        text-align: center;
        font-weight: 700;
    }
    .textContainerBlock{
        margin-top: 32px;
        padding: 0px;
    }
    .eventDate{
        font-family: 'Roboto', sans-serif;
        line-height: 140%;
        font-size: 28px;
        color: #fff;
        text-align: center;
        font-weight: 900 ;
    }
    .eventType{
        font-family: 'Roboto', sans-serif;
        line-height: 140%;
        font-size: 20px;
        color: #fff;
        text-align: center;
        font-weight: 900 ;
    }
    .lineUpImg{
        margin-bottom: 30px;
    }
    .lineupWriteup{
        font-family: 'Roboto', sans-serif;
        line-height: 140%;
        font-size: 18px;
        color: #fff;
        text-align: center;
        font-weight: 700 ;
        margin-top: 20px;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
*{
  font-family: 'Roboto', sans-serif;
}
/* background: linear-gradient(106.85deg, #A16EFF 13.38%, #59BDED 48.2%, rgba(255, 43, 255, 0.96) 100%);
background-size: 1000% 1000%;
animation: gradient 3s ease infinite !important; */
body{

  overflow-x: hidden;
}


@keyframes gradient {
0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}
}
@media only screen and (max-width: 600px) {
  body{
    overflow-x: hidden;
  }
  

  }
  
  /* @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
   } */
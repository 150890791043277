.navbarr{
    background-color: #ffdd0050;
    position: fixed;
    top: 0px;
    /* height: 100px; */
    width: 100%;
    /* padding: 12px 0px; */
    z-index: 9999999999;
}
  .logoHeader{
    max-width: 100%;
    height: 80px;
    margin-left: 20px;
  }
.navbarr.colorChange{
    background-color: #ffdd00eb;
    position: fixed;
    top: 0px;
    transition: .4s all ease-in-out;
}
.navbarr.logoHeader{
   height: 96px;
}
.logo_style{
    height: 140px;
    margin-top: -28px;
}
.navLinkStyle{
    font-family: 'Roboto';
    font-size: 19px;
    font-weight: 900;
    color: #000 !important;
    margin-right: 20px;
    margin-top: 12px;
}
.a#collasible-nav-dropdown{
    font-family: 'Roboto';
    font-size: 19px;
    font-weight: 900;
    color: #000 !important;
}
.dropdownStyle{
    font-family: 'Roboto';
    font-size: 20px;
    font-weight: 900;
    color: #000 !important;
}
.navbar-dark .navbar-nav .nav-link {
    color: rgb(0 0 0);
}
.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover{
    color: #000000 !important;
    text-decoration: underline;
    /* border-bottom: 2px solid #000; */
}
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #da00da !important;
    text-align: left;
    list-style: none;
    background-color: #9CFF01;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;
}
.navbar-dark .navbar-toggler-icon {
    background-image: url('../Assets/menu.svg') !important;
    border: none;
}
@media only screen and (max-width: 600px) {
    .logo_style{
        height: 100px;
        margin-top: -22px;
        margin-left: -10px ;
    }
    .navbarr{
        background-color: transparent;
        position: fixed;
        top: 0px;
        height: 80px;
        width: 100%;
        padding: 10px 0px;
        z-index: 9999999999;
    }
    .logoHeader {
        max-width: 100%;
        height: 56px;
        margin-left: 20px;
    }
    .ml-auto.navbar-nav{
        background-color: #ffdd00eb;
        padding: 10px 5%;
    }
   
    .navbar-dark .navbar-toggler{
        color: rgba(255,255,255,.5);
    border-color: rgba(255, 255, 255, 0);
    }
}





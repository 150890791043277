
.faqBlock{
    background-color: #38ECFE;
    padding-top: 140px;
    background-image: url('../Assets/bgFaq.svg');
    background-repeat: repeat;

}
.faqCard{
    padding: 24px 20px 32px 20px;
    background-color: #894aff;
    border-top: 8px solid #FF3351;
    border-left: 8px solid #FF2BFF;
    border-right: 8px solid #ED7300;
    border-bottom: 8px solid #eeff04;
}
.faqBottomSpace{
    margin-bottom: 32px;
}
.question{
    font-family: 'Roboto', sans-serif;
    font-size: 26px;
    color: #2bff39;
    line-height: 120%;
    margin-bottom: 10px;
    font-weight: 900;
}
.answer{
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    line-height: 140%;
    color: #fff;
    font-weight: 700;
    margin: 0px;
}
.highlightFaq{
    font-weight: bolder;
    color: hsl(60, 100%, 50%);
}
.fixedFramef{
    position: absolute;
    top: -80px;
    left: 36px;
    height: 100%;
}
.fixedFrameRightf{
    position: absolute;
    top: -80px;
    right: 36px;
    height: 100%;
}
.customHeight01{
    height: 258px;
}
.faqHeadMain{
    max-width: 100%;
    height: 120px;
    margin-bottom: 40px;
}
.centerFaq{
    text-align: center;
}
@media only screen and (max-width: 600px) {
    .question{
        font-size: 20px;
    }
    .answer{
        font-size: 16px;
    }
    .faqCard {
        padding: 18px 14px 18px 14px;
        background-color: #894aff;
        border-top: 4px solid #FF3351;
        border-left: 4px solid #FF2BFF;
        border-right: 4px solid #ED7300;
        border-bottom: 4px solid #eeff04;
    }
    .mobileSpaceFaq{
        margin-bottom: 30px;
    }
    .fixedFramef, .fixedFrameRightf{
        display: none;
    }
    .faqBlock{
        padding-bottom: 80px;
        padding-top: 10
        0px;
    }
    .customHeight01{
        height: auto;
    }
    .faqHeadMain{
        max-width: 100%;
        height: 80px;
        margin-bottom: 30px;
    }
}
.bgDanceBattle{
    background-color: #A16EFF;
}
.danceDattleImg, .danceDattleImgM{
  width: 100%;
    height: auto;
}
.danceBattleBlock{
    display: block;
    text-align: center;
}
.danceBattleBlockMobile{
    display: none;
}
.blockDb{
    padding: 120px 3%;
}
.dbPara{
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    color: #fff;
    line-height: 140%;
    font-weight: 700;
}
.highlightDb{
    font-weight: 900;
    color: yellow;
    font-size: 26px;
}
.headDb{
    font-weight: 900;
    color: #9CFF01;
    font-size: 38px;
    margin-top: 32px;
}
.questionsDb{
    font-weight: 900;
    color: #7A2682;
    font-size: 24px;
    margin-bottom: 8px;
}
.answerDb{
    font-weight: 500;
    color: #313131;
    font-size: 20px;
    margin-bottom: 8px;
}
.cardDbFaq{
    background-color: #fff;
    padding: 24px 18px;
    border: 6px solid #ED7300;
    margin-bottom: 30px;
}
.registerButton{
    text-align: center;
}
.registerBtn{
    padding: 18px 25%;
    background-color: #9CFF01;
    border-radius: 30px;
    width: 50%;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #000;
    border: 1px solid #000;
    box-shadow: 3px 3px 0px #000;
}
.registerBtn:hover{
    box-shadow: 0px 0px 0px #000;
    transition: .4s all ease-in;
    text-decoration: none;
    background-color: #000;
    color: #9CFF01 !important;
}
@media only screen and (max-width: 600px) {
    .danceBattleBlockMobile{
        display: block;
    }
    .danceBattleBlock{
        display: none;
    }
    .blockDb{
        padding: 60px 5%;
    }
    .dbPara {
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
    }
    .headDb{
        font-size: 32px;
    }
    .questionsDb{
        font-size: 20px;
    }
    .answerDb{
        font-size: 18px;
    }
    .cardDbFaq {
        background-color: #fff;
        padding: 18px 12px;
        border: 4px solid #ED7300;
        margin-bottom: 26px;
    }
}
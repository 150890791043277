.bannerBlock{
    background-color: #FF2BFF;
    padding-top: 40px;
}
.relP{
    position: relative;
}
.mainBannerBlock{
    text-align: center;
}
.aboutHeading{
    max-width: 100%;
    height: auto;
    margin-bottom: 26px;
}
.highlightAbout{
    color: #00ff3c;
}
.propsSocial{
    text-align: center;
}
.socialPropsImg{
    max-width: 100%;
    height: 150px;
    margin-top: -138px;
    margin-bottom: -53px;
    z-index: 999999;
}
.mainBannerImg{
    width: 90%;
    height: auto;
    border: 8px solid #FFDE00;
}
.knwMoreBtn{
    background-color: #FFDE00;
    color: #000;
    padding: 18px 38px 14px 38px;
    font-family: 'Roboto', sans-serif;
    line-height: 22px;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 900 !important;
    border: 2px solid #000;
    border-radius: 20px;
    margin: 0;
    text-decoration: none;
    transition: .3s all ease-in;
    box-shadow: 4px 4px 0px #000;
}
.knwMoreBtn:hover{
    transition: .2s all ease-in;
    box-shadow: 0px 0px 0px #000;
    background-color: #9CFF01;
    color: #000;
    text-decoration: none;
}
.btnBuyTickets {
    background-color: #00ff1a;
    color: #000;
    padding: 16px 32px 16px 32px;
    font-family: 'Roboto', sans-serif;
    line-height: 18px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 900 !important;
    border: 2px solid #000;
    margin-right: 40px;
    margin-top: 4px;
    border-radius: 20px;
    text-decoration: none;
    transition: .3s all ease-in;
    box-shadow: 4px 4px 0px #000;
}

.btnBuyTickets:hover {
    transition: .2s all ease-in;
    box-shadow: 0px 0px 0px #000;
    background-color: #9430ff;
    color: #fff;
    text-decoration: none;
}
.widthCustom{
    width: 90%;
    position: relative;
    margin: auto;
    padding: 100px 0px;
}
.aboutBlock{
    /* background-color: #8960EF; */
    width: 100%;
    text-align: center;
    background: linear-gradient(106.85deg, #A16EFF 13.38%, #59BDED 48.2%, rgba(255, 43, 255, 0.96) 100%);
    background-size: 1000% 1000%;
    animation: gradient 3s ease infinite !important;
}
@keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
   }
.leftCar, .rightCar{
    max-width: 100%;
    height: 160px;
    /* margin-left: 20%; */
}
.aboutAlign{
    text-align: center;
   
}
.para{
    font-family: 'Roboto', sans-serif;
    line-height: 140%;
    font-size: 22px;
    color: #fff;
    font-weight: 700 ;
    padding: 18px 6% 0px 6%;
}
.chainAttach{
    position: absolute;
    top: 0px;
    height: 100%;
    left: 0px;
}
.chainAttachRight{
    position: absolute;
    top: 0px;
    height: 100%;
    right: 0px;
}
.spaceAround{
    margin: 0px 202px;
}
.displatF{
    display: inline-flex;
    margin-top: 62px;
}
.whtsBlock{
    padding: 0px 0px 18px 0px;
   background-color: #A16EFF;
}
/* background-image: url('../Assets/bg.svg');
background-position: center;
background-size: contain;
background-repeat: repeat; */
.bgMain{
    width: 90%;
    margin: auto;
    padding-bottom: 100px;
}
.seprator{
    height: 18px;
    background-color: #FFDE00;
    border: 2px solid #000;
}
.headingBlockThird{
    text-align: center;
}
.whtsImg{
    max-width: 100%;
    height: 220px;
}
.cityCards{
    max-width: 100%;
    height: auto;
}
.cityCardsSpace{
    margin-top: 52px;
}
.ddcBlock{
    padding-bottom: 80px;
    background-color: #ED7300;
}
.ddcImg{
    max-width: 100%;
    height: 220px;
}
.alignCenter{
    text-align: center;
}
.cmngSoon{
    font-family: 'Roboto', sans-serif;
    line-height: 140%;
    font-size: 56px;
    color: #fff;
    font-weight: 700 ;
    margin: 3% 0px;
}
.sbaImg{
    max-width: 100%;
    height: 250px;
}
.ambCards{
    max-width: 100%;
    height: 500px;
}
.sbaBlock{
    padding-bottom: 100px;
    background-color: #38ECFE;
}
.ambassadorsCardsSpace{
    margin-top: 62px;
}
.ImageGalleryBlock{
    padding-bottom: 100px;
    background-image: url('../Assets/bg.svg');
background-position: center;
background-size: contain;
background-repeat: no-repeat;
background-color: #C38DFC;
}
.gallerySpace{
    padding-top: 52px;
}
.galleryImg{
    max-width: 100%;
    height: 260px;
}
.whtsImgM, .mainBannerImgMobile, .sbaImgM, .ddcImgM{
    display: none;

}
.whtsImg, .mainBannerImg, .sbaImg, .ddcImg{
    display: block;
    margin: auto;
}
.cityCards{
    margin-bottom: 52px;
}
.botsObject{
    margin: 0px 82px;
    max-width: 100%;
    height: auto;
}
.object {
    animation: MoveUpDown 1s linear infinite;
    position: absolute;
    left: 0;
    bottom: 0;
  }
  
  @keyframes MoveUpDown {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-100px);
    }
  }
  .zoom-in-zoom-out {
    -webkit-animation: zoom-in-zoom-out 1.3s ease-out infinite;
    animation: zoom-in-zoom-out 1.3s ease-out infinite;
}
.highlight{
    color: #FFDE00;
}
.clients-slideshow-main {
    background-size: 1500px;
    background-repeat: repeat-x;
    width: 100%;
    height: 100px;
    transform: rotate(90deg);
}
.clients-slideshow-main.slideright-r {
    background-image: url('../Assets/city/leftAttach.svg');
}
.clients-slideshow-main.slideright-r {
    animation: slideright 300s infinite linear;
    -webkit-animation: slideright 300s infinite linear;
    background-image: url('../Assets/city/leftAttach.svg');
}

@keyframes slideright {
    0%{
        background-position: 0px;
    }
    100%{
        background-position: -10000px;
    }
}

@keyframes zoom-in-zoom-out {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
.socialBlocks{
    background-color: #7A2682;
    padding: 90px 0px 50px 0px;
    margin-top: -25px;
}
.socialIconBlock{
    text-align: center;
}
.socialIcon{
    max-width: 100%;
    height: auto;
    transition: .3s all ease-in;
}
.socialIcon:hover{
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    transition: .4s all ease-in;
}
.mrl{
    margin: 0px 30px 0px 0px;
}
.FlinkLogo{
    max-width: 100%;
    height: 60px;
}
.sepratorl{
    margin: 0px 18px;
    padding: 0px !important;
    font-weight: 400 !important;
    font-family: 'Montserrat';
    font-size: 48px;
    color: #fff;
}
.flexBlock{
    display: inline-flex;
    margin-bottom: 32px;
}
.footerHeadLink{
    font-family: 'Roboto', sans-serif;
    line-height: 140%;
    font-size: 26px;
    color: #FFDE00 !important;
    text-align: center;
    font-weight: 900 ;
    margin-bottom: 42px;
}
.footerHeadLink{
    color: #FFDE00 !important;
}
.spaceBottomF{
    margin-bottom: 32px;
}
.logoFooter{
    max-width: 100%;
    height: 130px;
    margin-bottom: 28px;
}
.mobileCard, .galleryImgM{
    display: none;
}
@media only screen and (max-width: 767px) {
    .whtsImgM, .mainBannerImgMobile, .sbaImgM, .ddcImgM, .mobileCard , .galleryImgM{
        display: block;
        margin: auto;
    }
    .mainBannerImgMobile{
        max-width: 100%;
        height: auto;
    }
    .whtsImgM{
        max-width: 100%;
        height: 310px;
    }
    .whtsImg, .mainBannerImg, .sbaImg, .ddcImg, .desktopCard , .galleryImg{
        display: none;
    }
    .cmngSoon{
        font-size: 42px;
    }
    .sbaImgM, .ddcImgM{
        max-width: 100%;
        height: auto;
    }
    .spaceAround {
        margin: 0px 18px;
    }
    .leftCar, .rightCar {
        max-width: 100%;
        height: 76px;
        /* margin-left: 20%; */
    }
    .aboutHeading {
        height: 72px;
    }
    .aboutBlock{
        padding: 0px 0px 0px 0px;
    }
    .widthCustom{
        padding: 80px 0px;
    }
    .para {
        line-height: 140%;
        font-size: 16px;
        font-weight: 500;
        padding: 13px 5% 0px 5%;
    }
    .cityCards{
        height: auto;
        margin-bottom: 22px;
    }
    .whtsBlock {
        padding: 0px 0px 10px 0px;
    }
    .btnBuyTickets {
        padding: 12px 24px 12px 24px;
        line-height: 14px;
        font-size: 14px;
        font-weight: 600 !important;
        border: 1px solid #000;
        top: 20px;
        right: 20px;
        width: max-content;
        box-shadow: 3px 3px 0px #000;
    }
    .cityCardsSpace {
        margin-top: 32px;
    }
    .ambCards {
        max-width: 100%;
        height: auto;
    }
    .galleryImg {
        max-width: 100%;
        height: auto;
    }
    .spaceCards{
        margin-bottom: 58px;
    }
    .knwMoreBtn {
        padding: 14px 32px 14px 32px;
        line-height: 18px;
        font-size: 18px;
        font-weight: 700 !important;
        border: 1px solid #000;
        box-shadow: 3px 3px 0px #000;
    }
    .sbaBlock{
        padding: 0px 15px 40px 15px;
    }
    .ambassadorsCardsSpace{
        margin-top: 32px;
    }
    .bgMain{
        padding-bottom: 60px;
    }
    .botsObject{
        height: 80px;
        margin: -40px 4px;
    }
    .socialBlocks {
        background-color: #7A2682;
        padding: 50px 0px 54px 0px;
        margin-top: -25px;
    }
    .logoFooter{
        height: 100px;
    }
    .socialIcon{
        height: 38px;
    }
    .FlinkLogo{
        max-width: 100%;
        height: 42px;
    }
    .sepratorl {
        margin: 0px 18px;
        padding: 0px !important;
        font-weight: 400 !important;
        font-family: 'Montserrat';
        font-size: 30px;
    }
    .FlinkLogo{
        max-width: 100%;
        height: 40px;
    }
    .footerHeadLink{
        font-family: 'Roboto', sans-serif;
        line-height: 140%;
        font-size: 22px;
        color: #FFDE00 !important;
        text-align: center;
        font-weight: 900 ;
        margin-bottom: 32px;
    }
}
@media screen and (min-width: 767px) and (max-width:1023px) {
    .leftCar, .rightCar {
        max-width: 100%;
        height: 76px;
    }
    .botsObject {
        margin: 0px 66px;
        max-width: 100%;
        height: 148px;
    }
    .knwMoreBtn {
        padding: 14px 20px 14px 20px;
        font-size: 14px;
    }
    .whtsImg, .sbaImg ,.ambCards{
        max-width: 100%;
        height: auto;
    }
}